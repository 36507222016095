import { createStore, applyMiddleware, compose } from "redux";
import reducersYugo from "../yugo/reducers";
import reducersTranscription from "../transcription/reducers";
import reducersVendor from "../vendor/reducers";
import createSagaMiddleware from "redux-saga";
// import rootSaga from "../sagas/index";
import reduxThunk from "redux-thunk";
import secureLocalStorage from "react-secure-storage";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, reduxThunk];

// This condition for redirect page yugo , transcription and  vendor 

let YugoTranscription = secureLocalStorage.getItem('YugoTranscription') || true
let  isVendorLogIn = secureLocalStorage.getItem("login_vendor")

let reducers
if (YugoTranscription === "ToTranscription") {
  reducers = reducersTranscription
} else if (YugoTranscription === "ToYugo") {
  reducers = reducersYugo
} else if (YugoTranscription === "ToVendor") {
  reducers = reducersVendor
} else if (isVendorLogIn === 'login_vendor' ) {
  reducers = reducersVendor
}else{
  reducers = reducersYugo
}


function configureStore(preloadedState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducers, preloadedState, composeEnhancers(applyMiddleware(...middlewares)));

  // sagaMiddleware.run(rootSaga);

  if (module.hot && YugoTranscription === "ToTranscription") {
    module.hot.accept("../transcription/reducers/index", () => {
      const nextRootReducer = require("../transcription/reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  } else if (module.hot && YugoTranscription === "ToYugo") {
    module.hot.accept("../yugo/reducers/index", () => {
      const nextRootReducer = require("../yugo/reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  } else if (module.hot && YugoTranscription === "ToVendor") {
    module.hot.accept("../vendor/reducers/index", () => {
      const nextRootReducer = require("../vendor/reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  } else if (module.hot && isVendorLogIn === "login_vendor") {
    module.hot.accept("../vendor/reducers/index", () => {
      const nextRootReducer = require("../vendor/reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}


const store = configureStore();

export default store;


