import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import LoginReducer from "./login/login.reducer";
import LogoutReducer from "./login/logout.reducer";
import DashboardReducer from "./dashboard/dashboard.reducer";
import ProjectReducer from './project/project.reducer'
import UserReducer from './user/user.reducer'
import AdminReducer from './admin/admin.reducer'
import ProjectRequestReducer from './project_request/project_request.reducer'



const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  loginReducer: LoginReducer,
  logoutReducer: LogoutReducer,
  dashboardReducer: DashboardReducer,
  projectReducer: ProjectReducer,
  userReducer: UserReducer,
  adminReducer: AdminReducer,
  projectRequestReducer: ProjectRequestReducer,

});

export default reducers;
