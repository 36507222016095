import {
  PROJECT_SUCCESS, PROJECT_FAILURE, PROJECT_SUCCESS_TWO, PROJECT_FAILURE_TWO, PROJECT_SUCCESS_SECOND , PROJECT_SUCCESS_THREE
} from "../../constants/index";
import {
  onSuccess, onFailure, onSuccessTwo, onFailureTwo, onSuccessSecond, onSuccessThree
} from "../common";

const initStat = { data: [], dataTwo: [], dataSec: [], dataThree: [], error: false, errorData: "", isLoading: true };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initStat, action) => {
  switch (action.type) {
    case PROJECT_SUCCESS:
      return onSuccess(state, action);
    case PROJECT_SUCCESS_TWO:
      return onSuccessTwo(state, action);
    case PROJECT_SUCCESS_SECOND:
      return onSuccessSecond(state, action);
    case PROJECT_SUCCESS_THREE:
      return onSuccessThree(state, action);
    case PROJECT_FAILURE:
      return onFailure(state, action);
    case PROJECT_FAILURE_TWO:
      return onFailureTwo(state, action);
    default:
      return { ...state };
  }
};
