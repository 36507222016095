export const onSuccess = (state, action) => {
  return {
    ...state,
    data: action.payload,
    isLoading: false,
  };
};
export const onSuccessTwo = (state, action) => {
  return {
    ...state,
    dataTwo: action.payload,
    isLoading: false,
  };
};

export const onSuccessSecond = (state, action) => {
  return {
    ...state,
    dataSec: action.payload,
    isLoading: false,
  };
};

export const onSuccessThree = (state, action) => {
  return {
    ...state,
    dataThree: action.payload,
    isLoading: false,
  };
};

export const onFailure = (state, action) => {
  return { ...state, isLoading: false, errorData: action.payload, error: true };
};

export const onFailureTwo = (state, action) => {
  return { ...state, isLoading: false, errorData: action.payload, error: true };
};
