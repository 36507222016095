import { PROJECT_REQUEST_SUCCESS, PROJECT_REQUEST_FAILURE } from "../../constants/index";
import { getApi, putApi } from "../../../../utils/apiCall";

export const projectRequestSuccess = (data) => {
  return {
    type: PROJECT_REQUEST_SUCCESS,
    payload: data,
  };
};

export const projectRequestFailure = (error) => {
  return {
    type: PROJECT_REQUEST_FAILURE,
    payload: error,
  };
};

export const GetAllProjectRequest = () => {
  return async (dispatch, getState) => {
      const { error, result } = await getApi(`admin_project_request`);
      if (!error) {
          return dispatch(projectRequestSuccess(result));
      }
      return dispatch(projectRequestFailure(error));
  };
};


export const UpdateProjectPermission = (id,body) => {
  return async (dispatch, getState) => {
    const { error, result } = await putApi(`project/${id}`, body);
      if (!error) {
          return dispatch(projectRequestSuccess(result));
      }
      return dispatch(projectRequestFailure(error));
  };
};