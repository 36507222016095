import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { HOME_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route exact path={`${HOME_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboard`))} />

        <Redirect from={`${HOME_PREFIX_PATH}`} to={`${HOME_PREFIX_PATH}/dashboard`} />

      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
