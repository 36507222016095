import {
  DashboardOutlined,
  UserOutlined,
  BarChartOutlined,
  ProjectOutlined,
  CrownOutlined,
  SolutionOutlined,
  TeamOutlined,
  PicRightOutlined,
  CodeOutlined,
  // EditOutlined,
  PicCenterOutlined
} from "@ant-design/icons";
import {
  APP_PREFIX_PATH
} from "configs/AppConfig";
import secureLocalStorage from "react-secure-storage";

const admin_status = secureLocalStorage.getItem('is_super_admin');
const GoYugoOrAdmin = secureLocalStorage.getItem('YugoTranscription');
const isVendorLogIn = secureLocalStorage.getItem("login_vendor")

const dashBoardNavTree = [{
  key: "home",
  path: `${APP_PREFIX_PATH}/yugo/dashboard`,
  title: "Dashboard",
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "admin",
  path: `${APP_PREFIX_PATH}/yugo/admin`,
  title: "Admins",
  icon: CrownOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "projectrequest",
  path: `${APP_PREFIX_PATH}/yugo/admin/projectrequest`,
  title: "Project Request",
  icon: SolutionOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "project",
  path: `${APP_PREFIX_PATH}/yugo/project`,
  title: "Projects",
  icon: ProjectOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "user",
  path: `${APP_PREFIX_PATH}/yugo/user`,
  title: "Users",
  icon: UserOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "reports",
  path: `${APP_PREFIX_PATH}/yugo/reports`,
  title: "Project Metadata",
  icon: BarChartOutlined,
  breadcrumb: false,
  submenu: [],
},

];

const dashBoardNavTreeUser = [{
  key: "home",
  path: `${APP_PREFIX_PATH}/yugo/dashboard`,
  title: "Dashboard",
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "project",
  path: `${APP_PREFIX_PATH}/yugo/project`,
  title: "Projects",
  icon: ProjectOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "user",
  path: `${APP_PREFIX_PATH}/yugo/user`,
  title: "Users",
  icon: UserOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "reports",
  path: `${APP_PREFIX_PATH}/yugo/reports`,
  title: "Project Metadata",
  icon: BarChartOutlined,
  breadcrumb: false,
  submenu: [],
}
];


// Transcription Routing 

const dashBoardNavTranscription = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/transcription/dashboard`,
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "admin",
    path: `${APP_PREFIX_PATH}/transcription/admin`,
    title: "Admins",
    icon: CrownOutlined,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "projectrequest",
  //   path: `${APP_PREFIX_PATH}/transcription/admin/projectrequest`,
  //   title: "Project Request",
  //   icon: SolutionOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: "project",
    path: `${APP_PREFIX_PATH}/transcription/project`,
    title: "Projects",
    icon: ProjectOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "user",
    path: `${APP_PREFIX_PATH}/transcription/user`,
    title: "Users",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "vendor",
    path: `${APP_PREFIX_PATH}/transcription/vendor`,
    title: "Vendor",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "reports",
    path: `${APP_PREFIX_PATH}/transcription/reports`,
    title: "Project Report",
    icon: BarChartOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "fillerword",
    path: `${APP_PREFIX_PATH}/transcription/filler_word`,
    title: "Fiiler Word ",
    icon: PicRightOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "tags",
    path: `${APP_PREFIX_PATH}/transcription/tags`,
    title: "Tags",
    icon: CodeOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "label",
    path: `${APP_PREFIX_PATH}/transcription/label`,
    title: "Label",
    icon: PicCenterOutlined ,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "tags2",
    path: `${APP_PREFIX_PATH}/transcription/tags_two`,
    title: "Tags Two",
    icon: CodeOutlined,
    breadcrumb: false,
    submenu: [],
  }

];

const dashBoardNavTranscriptionUser = [{
  key: "home",
  path: `${APP_PREFIX_PATH}/transcription/dashboard`,
  title: "Dashboard",
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "project",
  path: `${APP_PREFIX_PATH}/transcription/project`,
  title: "Projects",
  icon: ProjectOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "user",
  path: `${APP_PREFIX_PATH}/transcription/user`,
  title: "Users",
  icon: UserOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "reports",
  path: `${APP_PREFIX_PATH}/transcription/reports`,
  title: "Project Report",
  icon: BarChartOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "vendor",
  path: `${APP_PREFIX_PATH}/transcription/vendor`,
  title: "Vendor",
  icon: TeamOutlined,
  breadcrumb: false,
  submenu: [],
}
];


const dashBoardNavVendor = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/vendor/dashboard`,
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  }, 
  // {
  //   key: "edit",
  //   path: `${APP_PREFIX_PATH}/vendor/edit_vendor`,
  //   title: "Edit Vendor",
  //   icon: EditOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // }
  
];

const HomeDashboard = [
  {
    key: "home",
    path: `/home/dashboard`,
    title: "Home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
]

let NavigationConfig
if (GoYugoOrAdmin === "ToYugo") {
  NavigationConfig = admin_status === true ? [...dashBoardNavTree] : [...dashBoardNavTreeUser];
} else if (GoYugoOrAdmin === "ToTranscription") {
  NavigationConfig = admin_status === true ? [...dashBoardNavTranscription] : [...dashBoardNavTranscriptionUser];
} else if (GoYugoOrAdmin === "ToVendor") {
  NavigationConfig = [...dashBoardNavVendor];
} else if (isVendorLogIn === "login_vendor" ){
  NavigationConfig = [...dashBoardNavVendor];
}else{
  NavigationConfig = [...HomeDashboard]
}




export default NavigationConfig;