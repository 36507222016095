// import React, { useEffect } from "react";
import { USER_LOGIN_SUCCESS, USER_LOGIN_FAILURE } from "../../constants/index";
import { setUrl } from "../../../../utils/yugoUtils";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

export const loginSuccess = (data) => {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: data,
  };
};

export const loginFailure = (error) => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: error,
  };
};

const headers = {
  Authorization: "F79B8C83B7C48BACA3DBE79546383.I56n-tYNC2Pqbm3fgYQWPoxA9Nc9OmKBy9WBqEdnBPo",
};
export const UserLogin = (body) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const response = await axios.post(`admin/login`, body, { baseURL: setUrl(), headers: headers });
      let {
        data: { data, success, message },
        status,
      } = response;
      if (success && status === 200) {
        // sessionStorage.setItem("user_id", response.data.result[0]._id);
        // sessionStorage.setItem("token", response.data.result[0].token_id);
        // sessionStorage.setItem("admin_status", response.data.result[0].is_super_admin);
        // sessionStorage.setItem("project_permission", response.data.result[0].project_permission);
        // sessionStorage.setItem("users_permission", response.data.result[0].users_permission);
        // sessionStorage.setItem("is_super_admin", response.data.result[0].is_super_admin);
        // sessionStorage.setItem("name", response.data.result[0].name);
        // sessionStorage.setItem("email", response.data.result[0].email);

        const userId = response.data.result[0]._id;
        secureLocalStorage.setItem("userId", userId);

        const tokenId = response.data.result[0].token_id;
        secureLocalStorage.setItem("tokenId", tokenId);

        let adminStatus = response.data.result[0].is_super_admin;
        if(adminStatus === true) {
          secureLocalStorage.setItem("admin_status", true);
        } else {
          secureLocalStorage.setItem("admin_status", false)
        }

        let projectPermission = response.data.result[0].project_permission;
        if(projectPermission === true) {
          secureLocalStorage.setItem("projectPermission", true);
        } else {
          secureLocalStorage.setItem("projectPermission", false);
        }

        let users_permission = response.data.result[0].users_permission;
        if(users_permission === true) {
          secureLocalStorage.setItem("users_permission", true);
        } else {
          secureLocalStorage.setItem("users_permission", false);
        }        

        let is_super_admin = response.data.result[0].is_super_admin;
        if(is_super_admin === true) {
          secureLocalStorage.setItem("is_super_admin", true);
        } else {
          secureLocalStorage.setItem("is_super_admin", false);
        }               

        const nameS = response.data.result[0].name;
        secureLocalStorage.setItem("nameS", nameS);

        const emailS = response.data.result[0].email;
        secureLocalStorage.setItem("emailS", emailS);

        resolve({ data, message });
        return dispatch(loginSuccess(data));
      } else {
        reject({ data, message });
        return dispatch(loginFailure(data));
      }
    });
  };
};
export const VendorLogin = (body) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const response = await axios.post(`vendor_login`, body, { baseURL: setUrl(), headers: headers });
      let {
        data: { result, success, message },
        status,
      } = response;
      if (success && status === 200) {
        // sessionStorage.setItem("user_id", response.data.result[0]._id);
        // sessionStorage.setItem("token", response.data.result[0].token_id);
        // sessionStorage.setItem("admin_status", response.data.result[0].is_super_admin);
        // sessionStorage.setItem("project_permission", response.data.result[0].project_permission);
        // sessionStorage.setItem("users_permission", response.data.result[0].users_permission);
        // sessionStorage.setItem("is_super_admin", response.data.result[0].is_super_admin);
        // sessionStorage.setItem("name", response.data.result[0].name);
        // sessionStorage.setItem("email", response.data.result[0].email);

        const userId = response.data.result[0]._id;
        secureLocalStorage.setItem("userId", userId);

        const tokenId = response.data.result[0].token_id;
        secureLocalStorage.setItem("tokenId", tokenId);

        let adminStatus = response.data.result[0].is_super_admin;
        if(adminStatus === true) {
          secureLocalStorage.setItem("admin_status", true);
        } else {
          secureLocalStorage.setItem("admin_status", false)
        }

        let projectPermission = response.data.result[0].project_permission;
        if(projectPermission === true) {
          secureLocalStorage.setItem("projectPermission", true);
        } else {
          secureLocalStorage.setItem("projectPermission", false);
        }

        let users_permission = response.data.result[0].users_permission;
        if(users_permission === true) {
          secureLocalStorage.setItem("users_permission", true);
        } else {
          secureLocalStorage.setItem("users_permission", false);
        }        

        let is_super_admin = response.data.result[0].is_super_admin;
        if(is_super_admin === true) {
          secureLocalStorage.setItem("is_super_admin", true);
        } else {
          secureLocalStorage.setItem("is_super_admin", false);
        }          
        
        const userDetails = response.data.result[0];
        secureLocalStorage.setItem("user_fbai", JSON.stringify(userDetails));

        const nameS = response.data.result[0].name;
        secureLocalStorage.setItem("nameS", nameS);

        const emailS = response.data.result[0].email;
        secureLocalStorage.setItem("emailS", emailS);

        resolve({ result, message });
        return dispatch(loginSuccess(result));
      } else {
        reject({ result, message });
        return dispatch(loginFailure(result));
      }
    });
  };
};
