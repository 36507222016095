import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter, useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AppLayout from "layouts/app-layout";
import HomeLayout from "layouts/home-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider, message } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, HOME_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import secureLocalStorage from "react-secure-storage";
import { UserLogout } from "redux/transcription/actions/logout";
import { VendorLogin } from "redux/yugo/actions/login/login.action";

function RouteInterceptor ({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function useQuery () {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Views = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let query = useQuery();
  const [loading, setLoading] = useState(true);
  const { locale, location, direction } = props;
  const token = secureLocalStorage.getItem("tokenId")?.trim();
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  let isVendorLogIn = secureLocalStorage.getItem("login_vendor") || 'true';

  if (query.get('email') && query.get('pass')) {
    if (JSON.parse(secureLocalStorage.getItem('isAuthenticated'))) {
      // secureLocalStorage.removeItem("authUser");
      secureLocalStorage.removeItem("tokenId");
      secureLocalStorage.removeItem("userId");
      secureLocalStorage.removeItem("admin_status");
      secureLocalStorage.removeItem("projectPermission");
      secureLocalStorage.removeItem("users_permission");
      secureLocalStorage.removeItem("is_super_admin");
      secureLocalStorage.removeItem("user_fbai");
      secureLocalStorage.removeItem('YugoTranscription');
      secureLocalStorage.removeItem('isAuthenticated');
    }
    dispatch(VendorLogin({ email: query.get('email'), password: query.get('pass') }))
      .then((response) => {
        secureLocalStorage.setItem("login_vendor", "login_vendor");
        secureLocalStorage.setItem("isAuthenticated", true);
        secureLocalStorage.setItem("YugoTranscription", "ToVendor");

        const userId = response.result[0]._id;
        secureLocalStorage.setItem("userId", userId);

        const tokenId = response.result[0].token_id;
        secureLocalStorage.setItem("tokenId", tokenId);

        let adminStatus = response.result[0].is_super_admin;
        if (adminStatus === true) {
          secureLocalStorage.setItem("admin_status", true);
        } else {
          secureLocalStorage.setItem("admin_status", false);
        }

        let projectPermission = response.result[0].project_permission;
        if (projectPermission === true) {
          secureLocalStorage.setItem("projectPermission", true);
        } else {
          secureLocalStorage.setItem("projectPermission", false);
        }

        let users_permission = response.result[0].users_permission;
        if (users_permission === true) {
          secureLocalStorage.setItem("users_permission", true);
        } else {
          secureLocalStorage.setItem("users_permission", false);
        }

        let is_super_admin = response.result[0].is_super_admin;
        if (is_super_admin === true) {
          secureLocalStorage.setItem("is_super_admin", true);
        } else {
          secureLocalStorage.setItem("is_super_admin", false);
        }

        const userDetails = response.result[0];
        secureLocalStorage.setItem("user_fbai", JSON.stringify(userDetails));

        const nameS = response.result[0].name;
        secureLocalStorage.setItem("nameS", nameS);

        const emailS = response.result[0].email;
        secureLocalStorage.setItem("emailS", emailS);

        // secureLocalStorage.setItem("authUser", JSON.stringify(response.result[0]));

        history.push("/app/vendor/dashboard");
        message.success({ type: 'success', content: response.message, duration: 0.5, maxCount: 1, key: 'login-message', onClose: () => { window.location.reload(); setLoading(false); } });
      })
      .catch((err) => message.error(err.message));
  } else {
    if (!JSON.parse(secureLocalStorage.getItem('isAuthenticated'))) {
      if (window.location.href.includes(process.env.REACT_APP_ADMIN_URL + 'app/vendor')) {
        window.location.href = process.env.REACT_APP_USER_URL;
      }
    }
  }

  useEffect(() => {
    if (JSON.parse(secureLocalStorage.getItem('isAuthenticated'))) {
      loading && setLoading(false);
    }
    else {
      if (!window.location.href.includes('app/vendor')) {
        loading && setLoading(false);
      }
    }
    if (!['/', '/home', '/auth', '/auth/login', '/auth/login_vendor', '/home/dashboard'].includes(history.location.pathname) && !query.get('email') && !query.get('email')) {
      dispatch(UserLogout(token)).then(resp => {
        if (resp?.payload?.result === false || resp.payload === true) {
          message.config({
            maxCount: 1
          });
          // secureLocalStorage.removeItem("authUser");
          secureLocalStorage.removeItem("tokenId");
          secureLocalStorage.removeItem("userId");
          secureLocalStorage.removeItem("admin_status");
          secureLocalStorage.removeItem("projectPermission");
          secureLocalStorage.removeItem("users_permission");
          secureLocalStorage.removeItem("is_super_admin");
          secureLocalStorage.removeItem("user_fbai");
          secureLocalStorage.removeItem("nameS");
          secureLocalStorage.removeItem("emailS");
          secureLocalStorage.removeItem('YugoTranscription');
          secureLocalStorage.removeItem('isAuthenticated');

          if (isVendorLogIn === "login_vendor") {
            message.error("Multiple Login Error");
            window.location.href = process.env.REACT_APP_USER_URL;
          } else {
            message.error("Multiple Login Error.");
            history.push("/auth/login");
          }
          secureLocalStorage.removeItem("login_vendor");
        }
      });
    }
    //eslint-disable-next-line
  }, [history.location.pathname]);

  return (
    loading ?
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', width: '100vw' }}>
        <LoadingOutlined
          style={{
            fontSize: 44,
            color: '#E05735'
          }}
          spin
        />
      </div>
      :
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
          <Switch>
            <Route exact path="/app">
              <Redirect to={HOME_PREFIX_PATH} />
            </Route>
            <Route exact path="/">
              <Redirect to={HOME_PREFIX_PATH} />
            </Route>
            <Route path={AUTH_PREFIX_PATH}>
              <AuthLayout direction={direction} isAuthenticated={token} />
            </Route>
            {isVendorLogIn !== 'login_vendor' && <RouteInterceptor path={`${APP_PREFIX_PATH}/yugo`} isAuthenticated={token}>
              <AppLayout direction={direction} location={location} />
            </RouteInterceptor>}
            {/* // Transcription  */}
            {isVendorLogIn !== 'login_vendor' && <RouteInterceptor path={`${APP_PREFIX_PATH}/transcription`} isAuthenticated={token}>
              <AppLayout direction={direction} location={location} />
            </RouteInterceptor>}
            {isVendorLogIn === 'login_vendor' && <RouteInterceptor path={`${APP_PREFIX_PATH}/vendor`} isAuthenticated={token}>
              <AppLayout direction={direction} location={location} />
            </RouteInterceptor>}
            <RouteInterceptor path={HOME_PREFIX_PATH} isAuthenticated={token}>
              <HomeLayout direction={direction} location={location} />
            </RouteInterceptor>
            <Redirect to={HOME_PREFIX_PATH} />
          </Switch>
        </ConfigProvider>
      </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
