import secureLocalStorage from "react-secure-storage";

export const isValidArray = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0;
};

export const isValidObject = (obj) => {
  return obj && Object.keys(obj).length > 0;
};

export const getQueryParams = (location, value) => {
  const params = new URLSearchParams(location.search);
  return params.get(value);
};

export const getUserId = () => {
  return secureLocalStorage.getItem("userId");
};

export const getUserToken = () => {
  return secureLocalStorage.getItem("tokenId");
};

export const isValidToken = (token) => {
  return secureLocalStorage.getItem("tokenId") === token;
};

export const setUrl = () => {
  const devUrl = process.env.REACT_APP_API;
  const prodUrl = process.env.REACT_APP_API;
  return process.env.NODE_ENV === "development" ? devUrl : prodUrl;
};
