import axios from "axios";
import { setUrl } from "./yugoUtils";
import secureLocalStorage from "react-secure-storage";

const token = secureLocalStorage.getItem("tokenId")?.trim();

const headers = {
  Authorization: token,
  Accept: "application/json",
};

export const postApi = async (url, data) => {
  try {
    const result = await axios.post(`${url}`, data, {
      headers: headers,
      baseURL: setUrl(),
    });
    if (result.status === 200) {
      return { error: false, result: result.data };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};

export const putApi = async (url, data) => {
  try {
    const result = await axios.put(`${url}`, data, {
      headers: headers,
      baseURL: setUrl(),
    });
    if (result.status === 200) {
      return { error: false, result: result.data };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};

export const getApi = async (url) => {
  try {
    const result = await axios.get(`${url}`, {
      headers: headers,
      baseURL: setUrl(),
    });
    if (result.status === 200) {
      return { error: false, result: result.data };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};

export const deleteApi = async (url) => {
  try {
    const result = await axios.delete(`${url}`, {
      headers: headers,
      baseURL: setUrl(),
    });
    if (result.status === 200) {
      return { error: false, result: result };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};
