import { USER_LOGOUT_SUCCESS, USER_LOGOUT_FAILURE } from "../../constants/index";
import { getApi } from "../../../../utils/apiCall";
// import secureLocalStorage from "react-secure-storage";

export const logoutSuccess = (data) => {
  return {
    type: USER_LOGOUT_SUCCESS,
    payload: data,
  };
};

export const logoutFailure = (error) => {
  return {
    type: USER_LOGOUT_FAILURE,
    payload: error,
  };
};

export const UserLogout = (user_id, userType) => {
  return async (dispatch, getState) => {

    const { error, result } = await getApi(`token/${user_id}/${userType}`);
    if (!error) {
      return dispatch(logoutSuccess(result));
    }
    return dispatch(logoutFailure(error));
  };
};
