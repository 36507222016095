import { PROJECT_SUCCESS, PROJECT_FAILURE, PROJECT_SUCCESS_ALL_PROJECTS } from "../../constants/index";
import { onSuccess, onFailure, onSuccessAllData } from "../common";

const initStat = { data: [], allData: [], error: false, errorData: "", isLoading: true };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initStat, action) => {
  switch (action.type) {
    case PROJECT_SUCCESS:
      return onSuccess(state, action);
    case PROJECT_SUCCESS_ALL_PROJECTS:
      return onSuccessAllData(state, action);
    case PROJECT_FAILURE:
      return onFailure(state, action);
    default:
      return { ...state };
  }
};
